@use '../../sass/abstracts' as *;

.content {
	width: 100%;

	padding: 3rem 2.5rem;

	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: min-content;

	h2 {
		grid-column: 1 / -1;
		grid-row: 1 / 2;
		justify-self: start;

		font-size: 1.8rem;
		margin-bottom: 2rem;
	}

	span {
		grid-column: 1 / -1;
		grid-row: 1 / 2;
		justify-self: end;

		color: var(--text-gray-dark);
		font-size: 1.4rem;
	}

	p {
		max-width: 60rem;
		color: var(--text-secondary);
		font-size: 1.4rem;
	}
}

.links {
	margin-top: 2.5rem;

	display: flex;
	gap: 2.5rem;

	li a {
		display: flex;
		align-items: center;

		&::before {
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 0.4rem;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

.technologies {
	margin-top: 2.5rem;

	display: flex;
	gap: 0.8rem;
	flex-wrap: wrap;

	position: relative;
	isolation: isolate;

	:global .gradient-item {
		padding: 0.8rem 1.6rem;
		font-size: 1.4rem;

		@include breakpoint-up('small') {
			padding: 1rem 1.8rem;
		}
	}
}
