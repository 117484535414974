.icon {
	&::before {
		content: '';
		display: inline-block;
		width: 2rem;
		height: 2rem;

		background-color: currentColor;
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
	}

	&.linkedin::before {
		mask-image: url('../../../public/icons/linkedin.svg');
	}

	&.telegram::before {
		mask-image: url('../../../public/icons/telegram.svg');
	}

	&.github::before {
		mask-image: url('../../../public/icons/github.svg');
	}

	&.leetcode::before {
		mask-image: url('../../../public/icons/leetcode.svg');
	}

	&.anchor::before {
		mask-image: url('../../../public/icons/link.svg');
	}
}
