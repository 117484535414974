@use '../../sass/abstracts' as *;

.card {
	padding: 2.5rem;
	padding-bottom: 3.5rem;

	display: grid;
	gap: 5rem;

	a:hover {
		text-decoration: underline;
	}

	@include breakpoint-up('small') {
		padding: 3.5rem;
	}

	@include breakpoint-up('medium') {
		grid-template-columns: 2fr 1fr 2fr;
		padding: 4.5rem;

		.links {
			grid-column: -1 / -2;
			grid-row: 1 / 2;
			align-self: start;
		}

		:global .button {
			grid-column: -1 / -2;
			grid-row: 1 / 2;
			align-self: end;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: 3rem;

	li {
		display: flex;
		flex-direction: column;
		gap: 0.8rem;
		width: max-content;

		strong {
			font-weight: 700;
		}
	}
}

.links {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	a {
		display: flex;
		align-items: center;
		width: max-content;
	}

	a::before {
		margin-right: 1.2rem;
	}
}
