@use '../abstracts' as *;

html {
	scroll-behavior: smooth;
	box-sizing: border-box;
	font-size: 62.5%; //rem = 10px
	height: 100%;
}

body {
	max-height: 100vh;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;

	background-color: var(--bg);
	transition: background-color 0.2s;
}

.container {
	margin: 0 auto;
	max-width: 120rem;
	padding: 0 2rem;
	width: 100%;

	@include breakpoint-up('small') {
		padding: 0 3rem;
	}

	@include breakpoint-up('medium') {
		padding: 0 4rem;
	}

	@include breakpoint-up('large') {
		padding: 0 5rem;
	}

	@include breakpoint-up('x-large') {
		padding: 0;
	}
}

section {
	margin-bottom: 12rem;

	@include breakpoint-up('medium') {
		margin-bottom: 14rem;
	}

	@include breakpoint-up('large') {
		margin-bottom: 16rem;
	}
}

*::selection {
	background-color: #fff;
	color: #000;
}
