@use 'variables' as *;

/* ------------------------------- Breakpoints ------------------------------ */

@mixin breakpoint-up($size) {
	@media (min-width: map-get($breakpoints-up, $size)) {
		@content;
	}
}

@mixin breakpoint-down($size) {
	@media (max-width: map-get($breakpoints-down, $size)) {
		@content;
	}
}

@mixin element {
	border-radius: var(--border-radius);

	color: var(--text-main);
	box-shadow: var(--shadow);
	background-color: var(--element-bg);
}
