@use '../abstracts' as *;

body {
	font-family: 'Poppins', sans-serif;
	color: var(--text-main);
	font-size: 1.6rem;
	font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}

strong {
	color: var(--text-main);
	font-weight: 700;
}

.title {
	color: var(--text-main);
	margin-bottom: 3rem;
	font-weight: 700;
	font-size: 3rem;

	@include breakpoint-up('medium') {
		font-size: 3.4rem;
	}

	@include breakpoint-up('large') {
		font-size: 4rem;
	}
}

p {
	line-height: 1.625;
}
