@use '../../sass/abstracts' as *;

.about {
	color: var(--text-secondary);

	max-width: 75rem;

	p + p {
		margin-top: 2.5rem;
	}
}
