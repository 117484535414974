*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-track {
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: var(--scrollbar-color);
}
