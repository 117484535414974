.slide-in-bottom {
	opacity: 0;

	&.visible {
		animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}
}

.fade-in {
	opacity: 0;

	&.visible {
		animation: fade-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	}
}

.tracking-in-expand {
	animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s both;
}

.text-focus-in {
	animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.75s both;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slide-in-bottom {
	0% {
		transform: translateY(100px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

@keyframes tracking-in-expand {
	0% {
		letter-spacing: -0.5em;
		opacity: 0;
	}
	40% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}
