@use '../abstracts' as *;

.button {
	position: relative;
	width: fit-content;
	margin-bottom: 11px;

	/* Default styles removal*/
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
	outline: inherit;

	&::after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;

		height: 1px;
		width: 50%;
		background-color: currentColor;
		transition: all 0.3s ease;
	}

	&:hover::after {
		width: 100%;
	}
}
