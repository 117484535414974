@use '../../sass/abstracts' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: 15px;

	&:hover {
		.card {
			background-image: radial-gradient(
				1200px circle at var(--mouse-x) var(--mouse-y),
				var(--spotlight-border-gradient-colors),
				transparent 35%
			);
		}
	}

	&:active {
		.card {
			background-image: radial-gradient(
				1200px circle at var(--mouse-x) var(--mouse-y),
				var(--spotlight-border-gradient-colors),
				transparent 60%
			);
		}
	}
}

.card {
	background-color: var(--spotlight-card-border);
	padding: 1px;

	&:hover {
		.content {
			background-image: radial-gradient(
				1200px circle at var(--mouse-x) var(--mouse-y),
				var(--spotlight-bg-gradient-colors),
				transparent 35%
			);
		}
	}

	&:active {
		.content {
			background-image: radial-gradient(
				1200px circle at var(--mouse-x) var(--mouse-y),
				var(--spotlight-bg-gradient-colors),
				transparent 60%
			);
		}
	}

	&.rounded {
		border-radius: 20px;
	}

	//TODO: Gradient item becomess blurry when transform in applied.
	&.animated {
		&:hover {
			// transform: scale(0.995);
			transform: scale(0.997);
		}

		&:active {
			transform: scale(0.99);
		}
	}
}

.content {
	transition: all 0.15s ease-in-out;
	background-color: var(--spotlight-card-bg);
	border-radius: inherit;
	width: 100%;
}
