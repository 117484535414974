@use '../../sass/abstracts' as *;

$t: 6s;
$color1: #124;
$color2: #614;
$color3: #469;
$color4: #c63;
$color5: #266;

// $color1: pink;
// $color2: rgb(220, 255, 226);
// $color3: transparent;
// $color4: rgb(220, 255, 226);
// $color5: rgb(194, 207, 233);
//rgb(220, 255, 226) green
//rgb(255, 192, 203) pink
//rgb(194, 207, 233) blue

.bokeh {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to bottom, #124 50%, var(--bg));
	position: absolute;
	inset: 0;

	transform: translateZ(0);
	backface-visibility: hidden;

	filter: blur(50px);
	filter: blur(8rem);
	filter: blur(3rem);

	@include breakpoint-up('small') {
		filter: blur(4rem);
	}

	@include breakpoint-up('medium') {
		filter: blur(5rem);
	}

	@include breakpoint-up('large') {
		filter: blur(6rem);
	}

	@include breakpoint-up('x-large') {
		filter: blur(7rem);
	}
}

.bokeh circle {
	animation: rotate $t linear infinite;

	fill: $color1;
	opacity: 0.43;
	transform-origin: 50%;

	&:nth-child(2n) {
		fill: $color2;
		transform-origin: 20% 110%;
		animation-delay: calc(-1 * $t / 2);
		animation-duration: calc($t * 0.6886);
		opacity: 0.2;
	}
	&:nth-child(3n) {
		opacity: 0.65;
		fill: $color3;
		transform-origin: 70% 30%;
		animation-delay: calc(-1 * $t / 4);
		animation-duration: $t * 1.3333;
	}
	&:nth-child(4n) {
		fill: $color4;
		transform-origin: 110% 70%;
		animation-delay: calc(-1 * $t / 3);
		animation-duration: $t * 0.79666;
	}
	&:nth-child(5n) {
		fill: $color5;
		transform-origin: 30% 40%;
	}
	&:nth-child(6n) {
		fill: $color1;
		transform-origin: -10% 80%;
		animation-delay: calc(-1 * $t * 1.3);
	}
	&:nth-child(7n) {
		fill: $color2;
		transform-origin: 70% -10%;
	}
	&:nth-child(8n) {
		fill: $color4;
		transform-origin: -30% 50%;
		animation-delay: calc(-1 * $t / 2.5);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
