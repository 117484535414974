@use '../../sass/abstracts' as *;

.footer {
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}

.logo {
	font-weight: 700;
	color: var(--text-main);
	font-size: 3rem;

	transition: transform 0.1s linear;
	transform-origin: center;

	&:hover,
	&:hover::before {
		transform: scale(1.1);
	}
}

.footer :global .container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 2.5rem;
	padding-top: 2.5rem;

	ul {
		display: flex;
		gap: 2.5rem;
		align-self: end;
	}

	.icon:hover {
		transform: scale(1.05);
	}
}
