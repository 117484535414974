@use '../../sass/abstracts' as *;

.hero {
	position: relative;
	min-height: 60rem;
	width: 100vw;
	height: 100vh;

	overflow-y: hidden;

	display: flex;
	justify-content: center;
	align-items: center;

	border-bottom: 1px solid var(--border-color);

	h1 {
		font-size: 3.2rem;
		margin-bottom: 0.5rem;
		margin-left: -0.065em;
	}

	h2 {
		font-size: 1.6rem;
		font-weight: 400;
		margin-bottom: 6rem;
	}

	@include breakpoint-up('small') {
		h1 {
			font-size: 4.1rem;
		}

		h2 {
			font-size: 1.8rem;
		}
	}

	@include breakpoint-up('medium') {
		h1 {
			font-size: 6rem;
			margin-bottom: 0.8rem;
		}

		h2 {
			font-size: 2.4rem;
			margin-bottom: 9rem;
		}
	}

	@include breakpoint-up('large') {
		h1 {
			font-size: 9rem;
		}
	}

	@include breakpoint-up('x-large') {
		h1 {
			font-size: 11rem;
		}
	}

	:global .container {
		transform: translateY(-25%);
	}
}

.actions {
	display: flex;
	gap: 6rem;
	font-size: 1.4rem;

	@include breakpoint-up('small') {
		font-size: unset;
	}
}
