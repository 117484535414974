@use '../../sass/abstracts' as *;

@property --rotate {
	syntax: '<angle>';
	initial-value: 132deg;
	inherits: false;
}

@keyframes spin {
	0% {
		--rotate: 0deg;
	}
	100% {
		--rotate: 360deg;
	}
}

/* Only use inside the element that forms his own stacking context, otherwise the shadow and border may be rendered below the parent's background due to z-index: -1 */

.gradient-item {
	--rotate: 132deg;

	position: relative;

	border: 1px solid var(--border-color);
	background-color: var(--item-bg);
	box-shadow: var(--shadow);
	border-radius: 8px;
	cursor: pointer;

	&:hover,
	&:active {
		color: rgb(88 199 250);
		transition: all 0.6s;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		inset: -1.7px;
		z-index: -1;
		border-radius: inherit;
		background: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
		animation: spin 6s linear infinite;
		transition: opacity 0.7s;
		opacity: 0;
	}

	&:hover::before,
	&:active::before {
		opacity: 1;
	}

	&:hover::after {
		filter: blur(3rem);
		opacity: 0.7;
	}

	&:active::after {
		filter: blur(4rem);
		opacity: 1;
	}
}
