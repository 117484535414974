@use '../../sass/abstracts' as *;

.skills {
	h2 {
		margin-bottom: 1.6rem;
		font-weight: 400;
		font-size: 2rem;
	}

	ul {
		margin-bottom: 4.5rem;
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem;

		:global .gradient-item {
			padding: 1.6rem 3rem;

			@include breakpoint-up('small') {
				padding: 1.6rem 4rem;
			}
		}
	}
}
