@use '../abstracts' as *;

body.dark {
	--bg: #090c18;
	--element-bg: #0c0f1e;
	--item-bg: hsl(223, 34%, 14%);

	--text-main: hsl(0, 0%, 100%);
	--text-secondary: hsl(215, 20%, 65%);
	--text-gray-dark: hsl(215, 16%, 47%);
	--text-hover: rgb(103, 227, 255);

	--border-color: hsl(231, 18%, 15%);
	--scrollbar-color: var(--item-bg);

	/* Spotlight */

	--spotlight-card-border: var(--border-color);
	--spotlight-card-bg: var(--element-bg);

	/* Cyan */
	// --spotlight-border-gradient-colors: rgba(0, 255, 241, 0.4);
	// --spotlight-bg-gradient-colors: rgba(0, 255, 241, 0.1);

	/* Blue */
	--spotlight-border-gradient-colors: rgba(29, 78, 216, 0.4);
	--spotlight-bg-gradient-colors: rgba(29, 78, 216, 0.05);
}
