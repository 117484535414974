@use '../abstracts' as *;

body.light {
	// --text-main: hsl(0, 0%, 20%);
	// --text-secondary: hsl(0, 0%, 55%);
	// --text-inverse: hsl(0, 0%, 100%);

	// --icon-bg: hsl(0, 0%, 55%);

	// --blur: hsla(0, 0%, 0%, 50%);

	// --shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

	// --input-border: hsl(0, 0%, 90%);

	--bg: #090c18;
	--element-bg: hsl(223, 34%, 14%);

	--text-main: hsl(0, 0%, 100%);
	--text-secondary: hsl(215, 20%, 65%);
	--text-hover: rgb(88 199 250);
	--text-hover: #67e3ff;
	--border-color: hsl(231, 18%, 15%);

	--bg: hsl(0, 0%, 98%);
	--element-bg: hsl(0, 0%, 100%);
	--item-bg: var(--element-bg);

	--text-main: hsl(0, 0%, 20%);
	--text-secondary: hsl(0, 0%, 55%);
	--text-secondary: var(--text-main);
	--text-gray-dark: hsl(215, 16, 47%);
	--text-hover: hsl(0, 0%, 100%);

	--border-color: hsl(0, 0%, 90%);

	--shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

	// color: hsl(215, 16, 47%);
	// color: hsl(215, 20, 65%);
	// color: rgb(148, 163, 184);

	// --text-gray-light: hsl(215, 20, 65%);
	// --text-gray-light: rgb(148, 163, 184);
	// --text-gray-light: #64748b;
}
